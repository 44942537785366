import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import {
  deleteWithAuth,
  getWithAuth,
  postWithAuth,
  putWithAuth,
} from "../../services/authentication";
import constants, { API } from "./../../constants";
import Avatar from "./../../component/common/avatar/avatar";
import Button from "./../../component/common/button/button";
import Input from "./../../component/common/inputtext/input-field";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { SpeedDial } from "primereact/speeddial";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import emptyAsset from "./../../assets/empty-recognition.svg";
import Spinner from "../../component/common/spinner/spinner";
import AssetFilterDialog from "../../component/human-resource/assetFilter";
import { FileUpload } from "primereact/fileupload";
import DeleteDialog from "../../component/common/confirm-delete-dialog/deleteDialog";
import SearchIconBar from "../../component/common/search-icon-searchBar/searchIconBar";
import Alphabet from "../../component/common/alphabet/alphabet";
import {
  PaginationDropdown,
  editDropDown,
  fn_firstcharacter,
  isAdminAccess,
  statusDropdown,
} from "../../utility/utility";
import ProfileUpload from "../../component/common/file-upload/fileUpload";
import { useParams } from "react-router-dom";
import ReadMore from "../../component/common/read-more/readMore";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { MaintenanceHistory } from "./AssetCategory/maintenance-history";
import Image from "../../utility/image";

export default function Asset() {
  const { assetId, assetName } = useParams();
  const [assets, setAssetDetails] = useState([]);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const orgId = user.orgId;
  const [displayBasic, setDisplayBasic] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [statusAction, setStatusAction] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const toast = useRef(null);
  const [assetURL, setCreateAssetURL] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [editSubmitDisable, setEditSubmitDisable] = useState(true);

  //pagination
  const [maxRecord, setMaxRecord] = useState();
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);
  //filter
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState({
    from: "",
    to: "",
    memberId: "",
    status: "",
  });
  const [filterDialog, setFilterDialog] = useState(false);
  const [show, setShow] = useState([]);
  const hrAdmin = isAdminAccess("Human Resource");
  const [updateItem, setUpdateItem] = useState({});

  const [createAsset, setCreateAsset] = useState({
    assetId: "",
    description: "",
    from: "",
    name: "",
    serialNumber: "",
    status: "",
    visible: true,
  });

  const [editAsset, setEditAsset] = useState({
    assetId: "",
    description: "",
    from: "",
    name: "",
    serialNumber: "",
    status: "",
    visible: true,
  });
  const [submitDisable, setSubmitDisable] = useState(true);
  const [imageUploadDisable, setImageUploadDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAssets();
  }, [statusAction]);

  const toastMessageShower = ({
    type,
    summary,
    message = "",
    detail = 3000,
  }) => {
    toast.current.show({
      severity: type,
      summary: summary,
      detail: message,
      life: detail,
    });
  };

  async function getAssets(searchString) {
    try {
      setSpinner(true);
      let from = "";
      let to = "";
      let memberId = "";
      if (filterValue.from) {
        from = new Date(filterValue.from).toLocaleDateString();
      }
      if (filterValue.to) {
        to = new Date(filterValue.to).toLocaleDateString();
      }
      const data = await getWithAuth(
        `${
          API.ASSET.CREATE_ASSET
        }?orgId=${orgId}&assetCategory=${assetId}&limit=${limit}&skip=${skip}&search=${
          searchString ? searchString : ""
        }&requestedDate=${from}&returnDate=${to}&memberId=${
          filterValue.memberId ? filterValue.memberId : ""
        }&status=${filterValue.status ? filterValue.status : ""}`,
        token
      );
      setAssetDetails(data.data.assets);
      setMaxRecord(data.data.assetsCount);
      setSpinner(false);
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary:
          err?.response?.data?.message ||
          err?.response?.data ||
          "Failed to load assets",
      });
    }
  }

  useEffect(() => {
    let y = Object.values(createAsset).every((val) => val !== "");
    if (y === true) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [createAsset]);

  useEffect(() => {
    let z = Object.values(editAsset).every((val) => val !== "");
    if (z === true) {
      setEditSubmitDisable(false);
    } else {
      setEditSubmitDisable(true);
    }
  }, [editAsset]);

  const Status = (data) => {
    return (
      <Avatar
        state={statusAction}
        setState={setStatusAction}
        data={data}
        getApi={getAssets}
      />
    );
  };

  const getDate = (data) => {
    return new Date(data.updatedAt).toLocaleDateString("en-uk", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const AssetName = (data) => {
    return (
      <div className="flex align-items-center column-gap-2">
        <Tooltip target=".maintenance" />

        {data.image ? (
          <img className="img-size" src={data.image} />
        ) : (
          <Alphabet letter={fn_firstcharacter(data?.name)} />
        )}
        {data.status === "Maintenance" && (
          <div className="maintenance" data-pr-tooltip="Maintenance Request">
            <img src={Image.maintanance} alt="" width={20} height={20} />
          </div>
        )}
        <div style={{ maxWidth: "200px" }}>
          <ReadMore text={data?.name} maxLength={50} />
        </div>
      </div>
    );
  };

  const deleteHandler = async () => {
    const id = updateItem._id;
    const url = `${constants.API.ASSET.DELETE_ASSET}?orgId=${orgId}&assetId=${id}`;
    try {
      const res = await deleteWithAuth(url, token);
      if (res.status === 200) {
        toastMessageShower({
          type: "success",
          summary: "Asset Deleted successfully",
        });
        setDeleteDialog(!deleteDialog);
        getAssets();
      }
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary:
          err?.response?.data?.message ||
          err?.response?.data ||
          "Failed to Delete asset",
      });
    }
  };

  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        let data = { ...updateItem };
        if (data.image === "") {
          delete data.image;
        }
        setEditAsset(data);
        setEditModel(!editModel);
        return;
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];
  const paginate = {
    RowsPerPageDropdown: () => {
      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={PaginationDropdown}
            onChange={(e) => {
              setLimit(e.value);
              setSkip(1);
              setFrom(1);
              setTo(e.value);
            }}
          />
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              setSkip(skip - 1);
              setFrom(from - limit);
              setTo(to - limit);
            }}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={to >= maxRecord ? true : false}
            onClick={() => {
              setSkip(skip + 1);
              setFrom(from + limit);
              setTo(to + limit);
            }}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };
  useEffect(() => {
    getAssets();
  }, [limit, skip]);

  const Action = (data) => (
    <div>
      <SpeedDial
        model={items}
        onClick={() => setUpdateItem(data)}
        radius={50}
        direction="right"
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
        buttonClassName="p-button-outlined action-btn-design"
      />
    </div>
  );

  const changeHandler = (e) => {
    setCreateAsset({ ...createAsset, [e.target.name]: e.target.value });
  };
  const updateAssetChangeHandler = (e) => {
    setEditAsset({ ...editAsset, [e.target.name]: e.target.value });
  };
  const submitHandler = async () => {
    try {
      const payload = { ...createAsset, assetCategory: assetId };
      if (assetURL) payload.image = assetURL;

      const response = await postWithAuth(
        `${API.ASSET.CREATE_ASSET}?orgId=${orgId}`,
        payload,
        token
      );

      if (response.status === 200) {
        toastMessageShower({
          type: "success",
          summary: "Asset Created Successfully",
        });
        setCreateAssetURL("");
        setCreateAsset({
          assetId: "",
          description: "",
          from: "",
          name: "",
          serialNumber: "",
          status: "",
          visible: true,
        });
        setDisplayBasic(!displayBasic);
        setStatusAction(true);
        getAssets();
        setCreateAssetURL("");
        setLoading(false);
      }
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary:
          err.response.data?.message ||
          err.response.data ||
          "Failed to create Asset",
      });
    }
  };
  const updateHandler = async () => {
    try {
      let data = { ...editAsset };
      if (editAsset.image === undefined) {
        data.image = "";
      }
      const response = await putWithAuth(
        `${API.ASSET.CREATE_ASSET}?orgId=${orgId}`,
        data
      );
      if (response.status === 200) {
        toastMessageShower({
          type: "success",
          summary: "Asset updated successfully",
        });
        setEditAsset({
          assetId: "",
          description: "",
          from: "",
          name: "",
          serialNumber: "",
          status: "",
          visible: true,
        });
        setEditModel(!editModel);
        getAssets();
      }
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary:
          err.response?.data?.message ||
          err.response?.data ||
          "Failed to update Asset",
      });
    }
  };

  const uploadHandler = async (e) => {
    try {
      setLoading(true);
      let files = e.files;
      if (files[0] !== undefined) {
        if (files[0].size < 1024000) {
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            uploadAssetProfile(files[0]);
          });
        } else {
          setLoading(false);
          toast.current.show({
            severity: "error",
            summary: "File Size too large",
            detail: "",
            life: 3000,
          });
        }
      }
      setImageUploadDisable(!imageUploadDisable);
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };
  const uploadAssetProfile = async (data) => {
    const fileData = new FormData();
    fileData.append("file", data);
    const url = constants.API.CLOUD.UPLOAD + "/asset/upload";
    const response = await postWithAuth(url, fileData, token);
    if (response) {
      setImageUploadDisable(!imageUploadDisable);
      if (editAsset.name !== "") {
        setEditAsset({ ...editAsset, image: response.data.URL });
        setLoading(false);
        return;
      }
      setCreateAssetURL(response.data.URL);
      setLoading(false);
      return;
    }
  };

  const imageRemoveHandler = async () => {
    try {
      setLoading(true);
      if (editAsset.image !== "" || editAsset.image !== undefined) {
        const url = constants.API.CLOUD.UPLOAD + "/asset/delete";
        await postWithAuth(url, { url: editAsset.image }, token);
        const data = { ...editAsset };
        delete data.image;
        setEditAsset(data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
        />
      )}

      {filterDialog && (
        <AssetFilterDialog
          filterDialog={filterDialog}
          setFilterDialog={setFilterDialog}
          update={statusAction}
          setUpdate={setStatusAction}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          setSkip={setSkip}
          setLimit={setLimit}
          setFrom={setFrom}
        />
      )}

      <div className="mr-6 flex flex-row-reverse justify-content-start">
        <div className="assets-actions">
          <div className="flex ml-5">
            <div className="search-bar flex align-items-center mr-6">
              <SearchIconBar
                value={search}
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                  getAssets(e.target.value);
                }}
              />
            </div>

            <Button
              className="filter-button"
              label="Filter"
              icon="pi pi-filter"
              onClick={() => setFilterDialog(!filterDialog)}
            />

            {hrAdmin && (
              <Button
                className="create-btn"
                label="Add"
                onClick={() => {
                  setDisplayBasic(!displayBasic);
                }}
              />
            )}
          </div>
        </div>
      </div>

      {!spinner && assets?.length === 0 && (
        <EmptyScreen
          img={emptyAsset}
          title={constants.EMPTY_SCREEN_TEXT[3].title}
          description={constants.EMPTY_SCREEN_TEXT[3].description}
        />
      )}
      {!spinner && assets.length && (
        <div className="flex flex-column row-gap-3 m-5">
          <div>
            All Assets {">"}{" "}
            <span
              style={{
                color: "rgba(22, 23, 25, 1)",
                fontSize: "1rem",
                fontWeight: "bolder",
              }}
            >
              {assetName}
            </span>
          </div>
          <DataTable
            className="asset-table-font"
            sortMode="multiple"
            value={assets}
            paginator
            paginatorTemplate={paginate}
            rows={limit}
            paginatorClassName="justify-content-end custom-dropdown"
            responsiveLayout="scroll"
          >
            <Column field={AssetName} header="Item Name" />
            <Column
              field={(asset) => (
                <div>
                  <Tooltip target=".asset">
                    <div className="flex flex-column row-gap-1  tooltip_container">
                      <div>{asset.name}</div>
                      <div>Asset ID : {asset.assetId}</div>
                      <div>Serial No. : {asset.serialNumber}</div>
                      <div>
                        Date of Purchase :{" "}
                        {moment(asset.createdAt).format("DD MMM, YYYY")}
                      </div>
                    </div>
                  </Tooltip>
                  <div className="asset">{asset.assetId}</div>
                </div>
              )}
              header="item ID"
              style={{ color: "rgba(90, 120, 238, 1)" }}
            />
            <Column field="serialNumber" header="Serial No" />
            <Column
              field={({ description }) => (
                <div style={{ width: "220px" }}>
                  <ReadMore text={description} maxLength={60} />
                </div>
              )}
              header="Description"
            />
            <Column body={getDate} header="Updated On" />
            <Column body={Status} header="Status" />
            {hrAdmin && <Column body={Action} header="Action" />}
          </DataTable>
        </div>
      )}
      {spinner && (
        <div className="flex  align-items-center justify-content-center h-screen">
          <Spinner value={spinner} />
        </div>
      )}

      {/* add asset dialog */}
      <Dialog
        header="Add Asset"
        visible={displayBasic}
        style={{ width: "50vw" }}
        onHide={() => {
          setDisplayBasic(!displayBasic);
          setCreateAsset({
            assetId: "",
            description: "",
            from: "",
            name: "",
            serialNumber: "",
            status: "",
            visible: true,
          });
          setCreateAssetURL("");
          setLoading(false);
        }}
      >
        <>
          <div className="mt-3">
            <ProfileUpload
              profileImg={assetURL}
              labelName="Asset Image"
              uploadHandler={uploadHandler}
              remove={() => setCreateAssetURL("")}
            />
          </div>

          <div className="grid">
            <div className="col-6">
              <Input
                className="asset-input"
                placeholder="Asset Name"
                name="name"
                label="Asset Name"
                type="text"
                onChange={changeHandler}
                required={true}
              />
            </div>

            <div className="col-6">
              <Input
                placeholder="Asset Id"
                name="assetId"
                label="Asset Id"
                type="text"
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Input
                placeholder="Serial No"
                name="serialNumber"
                label="Serial No"
                type="text"
                onChange={changeHandler}
              />
            </div>

            <div className="col-6">
              <Dropdown
                options={statusDropdown}
                placeholder="Status"
                value={createAsset.status}
                onChange={(e) =>
                  setCreateAsset({ ...createAsset, status: e.value })
                }
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-12">
              <InputTextarea
                placeholder="Description"
                name="description"
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="From"
                dateFormat="dd/mm/yy"
                value={createAsset.from}
                onChange={(e) =>
                  setCreateAsset({ ...createAsset, from: e.value })
                }
              />
            </div>
            <div className="col-6"></div>
          </div>

          <div className="grid mt-3 ml-2">
            Visibility
            <InputSwitch
              className="ml-2"
              checked={createAsset.visible}
              name="visible"
              onChange={(e) => {
                setCreateAsset({ ...createAsset, visible: e.value });
              }}
            />
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={submitDisable}
              loading={loading}
            />
          </div>
        </>
      </Dialog>

      {/* edit asset dialog */}
      <Dialog
        header="Edit Asset"
        visible={editModel}
        style={{ width: "50vw" }}
        onHide={() => {
          setEditModel(!editModel);
          setEditAsset({
            assetId: "",
            description: "",
            from: "",
            name: "",
            serialNumber: "",
            status: "",
            visible: true,
          });
        }}
      >
        <>
          <div className="mt-3">
            <ProfileUpload
              remove={imageRemoveHandler}
              uploadHandler={uploadHandler}
              labelName="Asset Image"
              profileImg={editAsset.image}
            />
          </div>

          <div className="grid">
            <div className="col-6">
              <Input
                className="asset-input"
                placeholder="Asset Name"
                name="name"
                label="Asset Name"
                type="text"
                onChange={updateAssetChangeHandler}
                value={editAsset.name}
              />
            </div>

            <div className="col-6">
              <Input
                placeholder="Asset Id"
                name="assetId"
                label="Asset Id"
                type="text"
                onChange={updateAssetChangeHandler}
                value={editAsset.assetId}
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Input
                placeholder="Serial No"
                name="serialNumber"
                label="Serial No"
                type="text"
                onChange={updateAssetChangeHandler}
                value={editAsset.serialNumber}
              />
            </div>

            <div className="col-6">
              <Dropdown
                options={editDropDown}
                placeholder="Status"
                value={editAsset.status}
                onChange={(e) =>
                  setEditAsset({ ...editAsset, status: e.value })
                }
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-12">
              <InputTextarea
                className="description-box"
                placeholder="Description"
                name="description"
                onChange={updateAssetChangeHandler}
                value={editAsset.description}
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="From"
                dateFormat="dd/mm/yy"
                value={new Date(editAsset.from)}
                onChange={(e) => setEditAsset({ ...editAsset, from: e.value })}
              />
            </div>
            <div className="col-6"></div>
          </div>
          <div className="ml-2 mt-1 flex align-items-center column-gap-2">
            <div>Visibility</div>
            <InputSwitch
              checked={editAsset.visible}
              name="visible"
              onChange={(e) => {
                setEditAsset({ ...editAsset, visible: e.value });
              }}
            />
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={updateHandler}
              disabled={editSubmitDisable}
              loading={loading}
            />
          </div>
        </>
      </Dialog>
    </>
  );
}
