import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../constants";
import { deleteWithAuth, getWithAuth, postWithAuth } from "../../../services/authentication";

export const getAnnouncements = createAsyncThunk(
    "/get/hr-announcements",
    async ({ fromDate = '', toDate = "", search = '' }, { rejectWithValue }) => {
        try {
            const url = `${API.ANNOUNCEMENT.CREATE}?from=${fromDate}&to=${toDate}&search=${search}`;
            const response = await getWithAuth(url);
            return response?.data || [];
        } catch (error) {
            rejectWithValue(error.response.data.message);
        }
    }
);


export const createAnnouncementApi = createAsyncThunk(
    "/create/hr-announcement",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await postWithAuth(API.ANNOUNCEMENT.CREATE, payload);
            return response.data;
        } catch (error) {
            rejectWithValue(error.response.data?.message || error.response.data);
        }
    }
);

export const deleteAnnoucement = createAsyncThunk(
    "/delete/hr-announcement",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await deleteWithAuth(API.ANNOUNCEMENT.CREATE, payload);
            return response.data;
        } catch (error) {
            rejectWithValue(error.response.data.message);
        }
    }
);