import React, { useEffect, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import Image from "../../../utility/image";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import Button from "../../../component/common/button/button";
import InputField from "../../../component/common/inputtext/input-field";
import {
  createAssetCategory,
  createAssetDepartmentApi,
  getAllCategoryApi,
  getAssetDepartmentApi,
  removeImageAsset,
  updateAssetCategory,
  uploadImageAsset,
} from "../../../store/features/HumanResource/asset";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";

import { ProgressSpinner } from "primereact/progressspinner";
import { isEmptyObject } from "../../../utility/utility";

export const AssetCategoryDialog = ({
  visible,
  setVisible,
  dispatch,
  preFillValue,
  isEditMode,
  toastMessageShower,
}) => {
  const imgUpload = useRef();
  const toast = useRef(null);
  const [isLoader, setIsLoader] = useState(false);
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const {
    getAssetDepartment,
    assetCategoryImage,
    uploadLoader,
    skip,
    limit,
    search,
  } = useSelector((store) => store.assets);
  const [assetCategoryValue, setAssetCategoryValue] = useState({
    assetName: "",
    assetPrefix: "",
    assetImage: "",
    assetDepartment: "",
    description: "",
  });
  const { assetName, assetPrefix, assetImage, assetDepartment, description } =
    assetCategoryValue;

  useEffect(() => {
    if (!isEmptyObject(preFillValue)) {
      const data = {
        assetName: preFillValue.assetName,
        assetPrefix: preFillValue.assetPrefix,
        assetImage: preFillValue.assetImage,
        description: preFillValue.description,
        assetDepartment: {
          ...preFillValue.assetDepartment,
          label: preFillValue.assetDepartment?.departmentName,
        },
      };
      setAssetCategoryValue(data);
    }
  }, [preFillValue]);

  useEffect(() => {
    if (memberDetails?.orgId)
      dispatch(getAssetDepartmentApi({ orgId: memberDetails.orgId }));
  }, [memberDetails]);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file.size < 1024000) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        uploadAssetCategoryImg(file);
      });
    } else {
      toastMessageShower({
        type: "error",
        summary: "File Size too large",
      });
    }
  };

  const uploadAssetCategoryImg = async (data) => {
    const formData = new FormData();
    formData.append("file", data);
    const response = await dispatch(uploadImageAsset(formData));
    if (!response.error) {
      setAssetCategoryValue((prev) => {
        return { ...prev, assetImage: response.payload.URL };
      });
    } else {
      toastMessageShower({
        type: "error",
        summary: response?.payload || "Failed to upload Asset Category image",
      });
    }
  };

  const handleRemoveImage = async () => {
    const response = await dispatch(removeImageAsset({ url: assetImage }));
    if (response.payload.message === "Profile Picture delete success.") {
      setAssetCategoryValue((prev) => {
        return { ...prev, assetImage: null };
      });
    }
  };

  const handleAssetDepartment = async (e) => {
    if (e.code === "Enter" && e.keyCode === 13) {
      const response = await dispatch(
        createAssetDepartmentApi({ departmentName: assetDepartment })
      );
      if (!response.error) {
        toastMessageShower({
          type: "success",
          summary: "Department added successfully",
        });
        setAssetCategoryValue({
          ...assetCategoryValue,
          assetDepartment: "",
        });
        dispatch(getAssetDepartmentApi({ orgId: memberDetails.orgId }));
      } else {
        toastMessageShower({
          type: "error",
          summary: response?.payload || "Failed to create Asset Department",
        });
      }
    }
  };

  const handleSave = async () => {
    try {
      setIsLoader(true);
      const payload = { ...assetCategoryValue };
      payload.assetDepartment = assetCategoryValue?.assetDepartment?._id || "";

      await dispatch(createAssetCategory(payload)).then((res) => {
        setIsLoader(false);
        if (res?.error) {
          toastMessageShower({
            type: "error",
            summary: res?.payload || "Failed to create Asset Category",
          });
          return;
        }
        toastMessageShower({
          type: "success",
          summary: "Asset Category Created Successfully",
        });
        dispatch(getAllCategoryApi({ skip, limit, search }));
        setVisible(!visible);
      });
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary: "Something went wrong",
      });
    }
  };

  const handleUpdate = async () => {
    setIsLoader(!isLoader);
    const data = {
      payload: {
        ...assetCategoryValue,
        assetDepartment: assetCategoryValue?.assetDepartment?._id,
      },
      id: preFillValue._id,
    };
    await dispatch(updateAssetCategory(data)).then((res) => {
      setIsLoader(!isLoader);
      if (!res?.error) {
        toastMessageShower({
          type: "success",
          summary: "Asset Category Updated Successfully",
        });
        dispatch(getAllCategoryApi({ skip, limit, search }));
        setVisible(!visible);
      } else {
        toastMessageShower({
          type: "error",
          summary: res?.payload || "Failed to update Asset Category",
        });
      }
    });
  };

  return (
    <Dialog
      header={`${isEditMode ? "Edit" : "New"} Asset Category`}
      visible={visible}
      style={{ width: "50vw" }}
      onHide={() => setVisible(!visible)}
    >
      <>
        <Toast ref={toast} />
        <div className="flex flex-column row-gap-2">
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload}
            ref={imgUpload}
          />
          {assetImage === "" ? (
            <div className="flex column-gap-2 align-items-center">
              <div
                id="upload_img"
                className="flex align-items-center justify-content-center"
              >
                {uploadLoader ? (
                  <ProgressSpinner
                    style={{ width: "34px", height: "34px" }}
                    strokeWidth="4"
                    animationDuration=".5s"
                  />
                ) : (
                  <img
                    src={Image.uploadImg}
                    alt="uploadImg"
                    width={34}
                    height={34}
                  />
                )}
              </div>

              <div
                className="upload_txt cursor-pointer"
                onClick={() => imgUpload.current.click()}
              >
                Upload image
              </div>
            </div>
          ) : (
            <div className="flex column-gap-2 align-items-center">
              <div
                id="upload_img"
                className="flex align-items-center justify-content-center"
              >
                {uploadLoader ? (
                  <ProgressSpinner
                    style={{ width: "34px", height: "34px" }}
                    strokeWidth="4"
                    animationDuration=".5s"
                  />
                ) : (
                  <img
                    src={assetImage}
                    alt="uploadImg"
                    width={34}
                    height={34}
                  />
                )}
              </div>

              <div
                className="remove_txt cursor-pointer"
                onClick={handleRemoveImage}
              >
                Remove image
              </div>
            </div>
          )}

          <div className="flex">
            <div className="flex flex-column row-gap-2 col-6">
              <label>Asset Name</label>
              <InputField
                placeholder="Enter Asset Name"
                name="assetName"
                onChange={(e) =>
                  setAssetCategoryValue({
                    ...assetCategoryValue,
                    assetName: e.target.value,
                  })
                }
                value={assetName}
              />
            </div>
            <div className="flex flex-column row-gap-2 col-6">
              <label>Asset Prefix</label>
              <InputField
                value={assetPrefix.toUpperCase()}
                placeholder="Asset Prefix"
                name="assetPrefix"
                onChange={(e) =>
                  setAssetCategoryValue({
                    ...assetCategoryValue,
                    assetPrefix: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="flex flex-column row-gap-2">
            <label>Department</label>
            <Dropdown
              name="assetDepartment"
              tooltip="Select or Type and Press Enter to Create New Department"
              tooltipOptions={{
                position: "bottom",
                className: "tooltip-font-size",
              }}
              editable={true}
              placeholder="Department"
              options={getAssetDepartment}
              value={assetDepartment}
              optionLabel="departmentName"
              onKeyDown={handleAssetDepartment}
              onChange={(e) => {
                setAssetCategoryValue({
                  ...assetCategoryValue,
                  assetDepartment: e.value,
                });
              }}
            />
          </div>

          <div className="flex flex-column row-gap-2">
            <label>Description</label>
            <InputTextarea
              className="description-box"
              autoResize
              value={description}
              onChange={(e) =>
                setAssetCategoryValue({
                  ...assetCategoryValue,
                  description: e.target.value,
                })
              }
              name="description"
            />
          </div>

          <div className="flex align-items-center justify-content-end">
            <Button
              label={isEditMode ? "Update" : "Save"}
              onClick={isEditMode ? handleUpdate : handleSave}
              loading={isLoader}
              disabled={!assetName || !assetPrefix || !assetDepartment}
            />
          </div>
        </div>
      </>
    </Dialog>
  );
};
