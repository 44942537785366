import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../axios/axios-interceptor";
import constants from "../../../constants";
import moment from "moment";

const initialState = {
  getAllAsset: [],
  getAssetDepartment: [],
  isLoading: false,
  assetCategoryImage: "",
  uploadLoader: false,
  search: "",
  skip: 1,
  limit: 10,
  from: 1,
  to: 10,
  maxRecord: 0,
  getHistory: {},
};

export const uploadImageAsset = createAsyncThunk(
  "upload/assetImg",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.CLOUD.UPLOAD + "/asset/upload";
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);

export const removeImageAsset = createAsyncThunk(
  "remove/assetImg",
  async (payload, { rejectWithValue }) => {
    try {
      // { url: editAsset.image }
      const url = constants.API.CLOUD.UPLOAD + "/asset/delete";
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      rejectWithValue(error.response?.data?.message || error.response?.data);
    }
  }
);

export const createAssetDepartmentApi = createAsyncThunk(
  "/create/asset-department",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.ASSET.ASSET_CATEGORY_DEPARTMENT;
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);
export const getAssetDepartmentApi = createAsyncThunk(
  "/get/asset-department",
  async ({ orgId, search = "" }, { rejectWithValue }) => {
    try {
      const url = `${constants.API.ASSET.ASSET_CATEGORY_DEPARTMENT}?orgId=${orgId}&search=${search}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createAssetCategory = createAsyncThunk(
  "/create/assetCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.ASSET.ASSET_CATEGORY;
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);

export const updateAssetCategory = createAsyncThunk(
  "/create/assetCategory",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      console.log("data in middleware", payload);
      const url = `${constants.API.ASSET.ASSET_CATEGORY}?id=${id}`;
      const response = await api.put(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);

export const getAllCategoryApi = createAsyncThunk(
  "/get/getAllCategoryApi",
  async ({ skip, limit, search }, { rejectWithValue }) => {
    try {
      const url = `${constants.API.ASSET.ASSET_CATEGORY}?skip=${skip}&limit=${limit}&search=${search}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);

export const deleteAssetCategoryApi = createAsyncThunk(
  "delete/assetcategory",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.ASSET.DELETE_ASSET_CATEGORY;
      const response = await api.put(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);
export const getMaintenanceHistory = createAsyncThunk(
  "get/getMaintenanceHistory",
  async ({ assetItemId }, { rejectWithValue }) => {
    try {
      const url = `${constants.API.ASSET.MAINTENANCE_HISTORY}?assetItemId=${assetItemId}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);

export const createMaintenanceHistory = createAsyncThunk(
  "post/move_to_maintenance",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.ASSET.MAINTENANCE_HISTORY;
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);
export const updateMaintenanceHistory = createAsyncThunk(
  "post/move_to_maintenance",
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      const url = `${constants.API.ASSET.MAINTENANCE_HISTORY}?id=${id}`;
      const response = await api.put(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);

export const deleteMaintenanceHistory = createAsyncThunk(
  "post/move_to_maintenance",
  async (id, { rejectWithValue }) => {
    try {
      const url = `${constants.API.ASSET.MAINTENANCE_HISTORY}?id=${id}`;
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);

export const moveToMaintenance = createAsyncThunk(
  "post/move_to_maintenance",
  async ({ assetId, data }, { rejectWithValue }) => {
    try {
      const url = `${constants.API.ASSET.MOVE_TO_MAINTENANCE}?assetId=${assetId}`;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.response?.data
      );
    }
  }
);

const assetSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setMaxRecord: (state, action) => {
      state.maxRecord = action.payload;
    },
  },
  extraReducers: {
    [getAssetDepartmentApi.fulfilled]: (state, { payload }) => {
      if (payload.department.length > 0) {
        state.getAssetDepartment = payload.department.map((d) => {
          return { ...d, label: d.departmentName };
        });
      } else {
        state.getAssetDepartment = [];
      }
    },
    [getAssetDepartmentApi.rejected]: (state) => {
      state.getAssetDepartment = [];
    },
    [getAllCategoryApi.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload?.category?.length > 0) {
        state.getAllAsset = payload.category?.map((asset) => {
          if (asset.status === "Active")
            return {
              ...asset,
              createdAt: moment(asset.createdAt).format("DD MMM, YYYY"),
            };
        });
      } else {
        state.getAllAsset = [];
      }
      state.maxRecord = payload.totalCounts;
    },
    [getAllCategoryApi.pending]: (state, { payload }) => {
      state.isLoading = true;
      state.getAllAsset = payload;
    },
    [getAllCategoryApi.rejected]: (state) => {
      state.isLoading = false;
      state.getAllAsset = [];
    },
    [uploadImageAsset.fulfilled]: (state, { payload }) => {
      state.uploadLoader = false;
      state.assetCategoryImage = payload.URL;
    },
    [uploadImageAsset.rejected]: (state, { payload }) => {
      state.uploadLoader = false;
    },
    [uploadImageAsset.pending]: (state, { payload }) => {
      state.uploadLoader = true;
    },
    [removeImageAsset.fulfilled]: (state) => {
      state.uploadLoader = false;
      state.assetCategoryImage = "";
    },
    [removeImageAsset.rejected]: (state) => {
      state.uploadLoader = false;
    },
    [removeImageAsset.pending]: (state) => {
      state.uploadLoader = true;
    },
    [getMaintenanceHistory.fulfilled]: (state, { payload }) => {
      if (payload?.history.length > 0) {
        state.getHistory = {
          assetItem: payload.history[0].assetItemId,
          history: payload.history,
        };
      } else {
        state.getHistory = {
          assetItem: null,
          history: [],
        };
      }
    },
    [getMaintenanceHistory.rejected]: (state) => {
      state.getHistory = {
        assetItem: null,
        history: [],
      };
    },
    [getMaintenanceHistory.pending]: (state) => {
      state.getHistory = {
        assetItem: null,
        history: [],
      };
    },
    [moveToMaintenance.fulfilled]: (state) => {},
  },
});

export const { setSkip, setLimit, setSearch, setFrom, setTo, setMaxRecord } =
  assetSlice.actions;

export default assetSlice.reducer;
