import React, { useState, useEffect, useRef } from "react";
import { Editor } from "primereact/editor";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import Input from "./../../component/common/inputtext/input-field";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import Button from "./../../component/common/button/button";
import { getWithAuth, postWithAuth } from "../../services/authentication";
import constants, { MIDDLEWARE_STATUS } from "../../constants";
import AnnouncementCard from "../../component/human-resource/announcementCard";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import emptyAsset from "./../../assets/empty-recognition.svg";
import Spinner from "../../component/common/spinner/spinner";
import SearchIconBar from "../../component/common/search-icon-searchBar/searchIconBar";
import { isAdminAccess } from "../../utility/utility";
import { useDispatch } from "react-redux";
import {
  getAnnouncements,
  createAnnouncementApi,
} from "../../store/features/HumanResource/announcement";

export default function Announcement() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [createAnnouncementDialog, setCreateAnnouncementDialog] =
    useState(false);
  const [createAnnouncement, setCreateAnnouncement] = useState({
    chats: true,
    title: "",
    dismissable: false,
    date: "",
    expiredAt: "",
  });
  const [description, setDescription] = useState();
  const [allAnnouncement, setAllAnnouncement] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const toast = useRef(null);
  const hrAdmin = isAdminAccess("Human Resource");

  useEffect(() => {
    let y = Object.values(createAnnouncement).every((val) => val !== "");
    if (y === true) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [createAnnouncement]);

  useEffect(() => {
    getAllAnnouncement();
  }, []);

  async function getAllAnnouncement(search) {
    try {
      setSpinner(true);
      const { payload } = await dispatch(
        getAnnouncements({ search: search || "" })
      );
      setAllAnnouncement(payload?.announcements || []);
      setSpinner(false);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err?.message || "something went wrong",
        detail: "",
        life: 3000,
      });
    }
  }

  const changeHandler = (e) => {
    setCreateAnnouncement({
      ...createAnnouncement,
      [e.target.name]: e.target.value,
    });
  };

  const toastMessageShower = ({
    type,
    summary,
    message = "",
    detail = 3000,
  }) => {
    toast.current.show({
      severity: type,
      summary: summary,
      detail: message,
      life: detail,
    });
  };

  async function submitHandler() {
    try {
      const data = { ...createAnnouncement, description: description };
      const res = await dispatch(createAnnouncementApi(data));
      if (res.meta.requestStatus === MIDDLEWARE_STATUS.FULFILLED) {
        toastMessageShower({
          type: "success",
          summary: "Announcement created successfully",
        });
        setCreateAnnouncementDialog(!createAnnouncementDialog);
        getAllAnnouncement();
        setCreateAnnouncement({});
      }
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary: err?.response?.data?.message || "Faile to create announcement",
      });
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Create Announcement"
        visible={createAnnouncementDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setCreateAnnouncementDialog(!createAnnouncementDialog);
          setCreateAnnouncement({});
        }}
      >
        <div className="flex flex-column row-gap-2 pt-4">
          <div className="col-12">
            <Input
              className="asset-input"
              placeholder="Title"
              name="title"
              label="Title"
              type="text"
              value={createAnnouncement.title}
              onChange={changeHandler}
            />
          </div>
          <div className="col-12">
            <Editor
              className="editor-border"
              style={{ height: "200px" }}
              value={createAnnouncement.description}
              onTextChange={(e) => setDescription(e.htmlValue)}
            />
          </div>

          <div className="flex justify-content-center">
            <div className="col-6">
              <Calendar
                placeholder="Start"
                label="Start"
                showTime
                hourFormat="12"
                dateFormat="dd/mm/yy"
                maxDate={new Date(createAnnouncement.expiredAt)}
                readOnlyInput
                value={createAnnouncement.date}
                onChange={(e) =>
                  setCreateAnnouncement({
                    ...createAnnouncement,
                    date: e.value,
                  })
                }
              />
            </div>

            <div className="col-6">
              <Calendar
                placeholder="Untill"
                dateFormat="dd/mm/yy"
                minDate={new Date(createAnnouncement.date)}
                readOnlyInput
                showTime
                hourFormat="12"
                value={createAnnouncement.expiredAt}
                onChange={(e) =>
                  setCreateAnnouncement({
                    ...createAnnouncement,
                    expiredAt: e.value,
                  })
                }
              />
            </div>
          </div>

          <div className="flex align-items-center column-gap-2">
            <div className="col-3 flex align-items-center column-gap-2">
              <label>Enable Chat</label>
              <InputSwitch
                checked={createAnnouncement.chats}
                name="chats"
                onChange={(e) => {
                  setCreateAnnouncement({
                    ...createAnnouncement,
                    chats: e.value,
                  });
                }}
              />
            </div>

            <div className="col-3 flex align-items-center column-gap-2">
              <label>Dismissable</label>
              <InputSwitch
                checked={createAnnouncement.dismissable}
                name="dismissable"
                onChange={(e) => {
                  setCreateAnnouncement({
                    ...createAnnouncement,
                    dismissable: e.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="flex justify-content-end">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={submitDisable}
            />
          </div>
        </div>
      </Dialog>

      <div>
        <div className="mr-6 flex flex-row-reverse justify-content-start">
          <div className="assets-actions">
            <div className="flex ml-5">
              <div className="search-bar flex align-items-center mr-7">
                <SearchIconBar
                  placeholder="Search"
                  onChange={(e) => {
                    getAllAnnouncement(e.target.value);
                  }}
                />
              </div>

              {hrAdmin && (
                <Button
                  className="create-btn"
                  onClick={() =>
                    setCreateAnnouncementDialog(!createAnnouncementDialog)
                  }
                  label="Create"
                />
              )}
            </div>
          </div>
        </div>
        <div className="grid flex justify-content-center">
          {spinner && <Spinner value={spinner} />}
        </div>
        {allAnnouncement && allAnnouncement.length > 0 && !spinner && (
          <AnnouncementCard
            allAnnouncement={allAnnouncement}
            getAllAnnouncement={getAllAnnouncement}
            toastMessageShower={toastMessageShower}
          />
        )}
        <div className="grid flex justify-content-center">
          {" "}
          {!spinner && allAnnouncement && allAnnouncement.length === 0 && (
            <EmptyScreen
              img={emptyAsset}
              title={constants.EMPTY_SCREEN_TEXT[4].title}
              description={constants.EMPTY_SCREEN_TEXT[4].description}
            />
          )}
        </div>
      </div>
    </>
  );
}
