import React, { useState, useEffect, useRef } from "react";
import "./announcementCard.scss";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Calendar } from "primereact/calendar";
import constants from "../../constants";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import {
  deleteWithAuth,
  getWithAuth,
  postWithAuth,
  putWithAuth,
} from "../../services/authentication";
import Input from "../common/inputtext/input-field";
import { Toast } from "primereact/toast";
import { SpeedDial } from "primereact/speeddial";
import Button from "../common/button/button";
import { InputSwitch } from "primereact/inputswitch";
import DeleteDialog from "../common/confirm-delete-dialog/deleteDialog";
import {
  DateTimeFormat,
  fn_firstcharacter,
  isAdminAccess,
} from "../../utility/utility";
import Alphabet from "../common/alphabet/alphabet";
import extractContent from "../../factory-function/extractTextFromHTML";
import ReadMore from "../common/read-more/readMore";

export default function AnnouncementCard({
  allAnnouncement,
  getAllAnnouncement,
  toastMessageShower,
}) {
  const token = localStorage.getItem("token");
  const toast = useRef(null);
  const [commentDialog, setCommentDialog] = useState(false);
  const [announcementDetails, setAnnouncementDetails] = useState({
    chats: true,
    title: "",
    dismissable: false,
    date: "",
    expiredAt: "",
  });
  const [announcementComments, setAnnounceComments] = useState([]);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [comment, setComment] = useState("");
  const [submitDisable, setSubmitDisable] = useState(true);
  const [description, setDescription] = useState();
  const hrAdmin = isAdminAccess("Human Resource");

  useEffect(() => {
    let y = Object.values(announcementDetails).every((val) => val !== "");
    if (y === true) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [announcementDetails]);

  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        setDescription(announcementDetails.description);
        setEditDialog(!editDialog);
        return;
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  async function deleteHandler() {
    try {
      const url =
        constants.API.ANNOUNCEMENT.CREATE + "/" + announcementDetails._id;
      const res = await deleteWithAuth(url, token);
      if (res.status === 200) {
        toastMessageShower({
          type: "success",
          summary: "Announcement deleted successfully",
        });
        setDeleteDialog(!deleteDialog);
        setAnnouncementDetails({});
        getAllAnnouncement();
      }
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary: err?.response?.data?.message || 'Failed to delete announcement',
      });
    }
  }

  const getComments = async (id) => {
    const url = constants.API.ANNOUNCEMENT.COMMENT + `?announcementId=` + id;
    const response = await getWithAuth(url, token);
    setAnnounceComments(response.data.announcementComments);
  };
  function changeHandler(e) {
    setComment(e.target.value);
  }
  async function createCommentHandler(e) {
    try {
      if (e.key === "Enter") {
        const url = constants.API.ANNOUNCEMENT.COMMENT;
        const data = {
          comment: comment,
          announcementId: announcementDetails._id,
        };
        const res = await postWithAuth(url, data, token);
        if (res.status == 200) {
          getComments(announcementDetails._id);
          setComment("");
          setAnnouncementDetails({
            ...announcementDetails,
            commentsCount: announcementDetails.commentsCount + 1,
          });
        }
      }
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary: err?.response?.data?.message || 'Failed to create comments',
      });
    }
  }
  async function editSubmitHandler() {
    try {
      const url = constants.API.ANNOUNCEMENT.CREATE;
      let data = { ...announcementDetails };
      data.description = description;
      const res = await putWithAuth(url, data, token);
      if (res.status === 200) {
        toastMessageShower({
          type: "success",
          summary: "Announcement edited successfully",
        });
        getAllAnnouncement();
        setAnnouncementDetails({});
        setEditDialog(!editDialog);
      }
    } catch (err) {
      toastMessageShower({
        type: "error",
        summary: err?.response?.data?.message || 'Failed to edit announcement',
      });
    }
  }
  return (
    <div>
      <Toast ref={toast} />
      <DeleteDialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        onClick={deleteHandler}
      />
      <Dialog
        header="Comments"
        visible={commentDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setCommentDialog(!commentDialog);
          setAnnouncementDetails({});
          getAllAnnouncement();
        }}
      >
        <div className="flex flex-column row-gap-2 ">
          <div className="announcement-header-font">
            <p>{announcementDetails.title}</p>
          </div>
          <div className="px-3">
            <span
              className="w-full"
              style={{ fontSize: "13px", color: "#82858d" }}
              dangerouslySetInnerHTML={{
                __html: announcementDetails.description,
              }}
            ></span>
          </div>
          <div className="flex justify-content-between align-items-center w-full">
            <div className="flex column-gap-2 DateTime">
              <i
                className="pi pi-calendar-times"
                style={{ fontSize: "1.5em" }}
              ></i>
              <div>{DateTimeFormat(announcementDetails?.date)}</div>
            </div>

            <div className="flex justify-content-end column-gap-3">
              <p className="flex justify-content-center align-items-center announcement-time">
                Announced by
              </p>
              <Tooltip target=".disabled-button" />
              <span
                data-pr-tooltip={announcementDetails.createdByName}
                data-pr-position="bottom"
                data-pr-classname="blue-tooltip"
                className="disabled-button"
              >
                <Alphabet
                  letter={fn_firstcharacter(announcementDetails.createdByName)}
                />
              </span>
              <div>
                <i
                  className="pi pi-comments p-text-secondary p-overlay-badge"
                  style={{ fontSize: "2rem" }}
                >
                  <Badge
                    severity="warning"
                    value={announcementDetails.commentsCount}
                  />
                </i>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="flex flex-column row-gap-2">
          <div className="announcement-header-font">Comments</div>
          <div className="pt-3">
            <Input
              className="asset-input"
              placeholder="Comment"
              name="comment"
              label="Type Your Comment Here And Click Enter"
              type="text"
              value={comment}
              onChange={changeHandler}
              required={true}
              onKeyDown={createCommentHandler}
            />
          </div>
          {announcementComments.map((comments, id) => {
            return (
              <div
                key={id}
                className="px-2 flex align-items-center justify-content-between"
              >
                <div className="col-8 flex column-gap-2 ">
                  <Tooltip target=".disabled-button" />
                  <div
                    className="disabled-button flex align-items-center justify-content-center"
                    data-pr-tooltip={comments?.empName}
                    data-pr-position="bottom"
                    data-pr-classname="blue-tooltip"
                  >
                    <Alphabet letter={fn_firstcharacter(comments?.empName)} />
                  </div>
                  <span
                    style={{ color: "#82858d" }}
                    className="flex align-items-center justify-content-center"
                  >
                    <ReadMore text={comments?.comment} maxLength={80} />
                  </span>
                </div>

                <div className="col-4 time__font">
                  {/* {new Date(comments.createdAt).toLocaleTimeString(
                    navigator.language,
                    { hour: "2-digit", minute: "2-digit" }
                  )} */}
                  {DateTimeFormat(comments.createdAt)}
                </div>
              </div>
            );
          })}
        </div>
      </Dialog>
      <Dialog
        header="Edit Announcement"
        visible={editDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setEditDialog(!editDialog);
          setAnnouncementDetails({});
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-12">
              <Input
                className="asset-input"
                placeholder="Title"
                name="title"
                label="Title"
                type="text"
                onChange={(e) =>
                  setAnnouncementDetails({
                    ...announcementDetails,
                    title: e.target.value,
                  })
                }
                required={true}
                value={announcementDetails.title}
              ></Input>
            </div>

            <div className="col">
              <Editor
                className="editor-border"
                style={{ height: "200px" }}
                onTextChange={(e) => setDescription(e.htmlValue)}
                value={description}
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="Start"
                label="Start"
                showTime
                hourFormat="12"
                dateFormat="dd/mm/yy"
                maxDate={new Date(announcementDetails.expiredAt)}
                readOnlyInput
                value={new Date(announcementDetails.date)}
                onChange={(e) =>
                  setAnnouncementDetails({
                    ...announcementDetails,
                    date: e.value,
                  })
                }
              ></Calendar>
            </div>

            <div className="col-6">
              <Calendar
                placeholder="Untill"
                showTime
                hourFormat="12"
                dateFormat="dd/mm/yy"
                minDate={new Date(announcementDetails.date)}
                readOnlyInput
                value={new Date(announcementDetails.expiredAt)}
                onChange={(e) =>
                  setAnnouncementDetails({
                    ...announcementDetails,
                    expiredAt: e.value,
                  })
                }
              />
            </div>
          </div>
          <div className="grid mt-3 ml-2">
            Enable Chat
            <InputSwitch
              className="ml-2"
              checked={announcementDetails.chats}
              name="chats"
              onChange={(e) => {
                setAnnouncementDetails({
                  ...announcementDetails,
                  chats: e.value,
                });
              }}
            />
          </div>
          <div className="grid mt-3 ml-2">
            Dismissable
            <InputSwitch
              className="ml-2"
              checked={announcementDetails.dismissable}
              name="dismissable"
              onChange={(e) => {
                setAnnouncementDetails({
                  ...announcementDetails,
                  dismissable: e.value,
                });
              }}
            />
          </div>
          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={editSubmitHandler}
              submitDisable={submitDisable}
            />
          </div>
        </div>
      </Dialog>
      {/* Annoucement card */}
      {allAnnouncement.map((announcement, id) => {
        return (
          <div className="card flex justify-content-center" key={id}>
            <div className="container">
              <div className="flex justify-content-between align-items-center">
                <div className="announcement-header-font">
                  {announcement.title}
                </div>
                <div>
                  {hrAdmin ? (
                    <SpeedDial
                      model={items}
                      onClick={() => setAnnouncementDetails(announcement)}
                      radius={50}
                      direction="left"
                      showIcon="pi pi-ellipsis-v"
                      hideIcon="pi pi-times"
                      buttonClassName="p-button-outlined action-btn-design"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="w-10 px-2">
                <ReadMore
                  text={extractContent(announcement.description)}
                  maxLength={250}
                />
              </div>
              {/* <div
                  dangerouslySetInnerHTML={{ __html: announcement.description }}
                ></div> */}
              <div className="flex justify-content-between">
                <div className="flex align-items-center column-gap-2 DateTime">
                  <i
                    className="pi pi-calendar-times"
                    style={{ fontSize: "1.5em" }}
                  ></i>
                  <div>
                    {DateTimeFormat(announcement?.date)} -{" "}
                    {DateTimeFormat(announcement?.expiredAt)}
                  </div>
                </div>

                <div className="flex justify-content-end  column-gap-2 align-items-center">
                  <Tooltip target=".disabled-button" />
                  <div className="announcement-time flex justify-content-center align-items-center">
                    Announced by
                  </div>
                  <span
                    data-pr-tooltip={announcement?.createdByName}
                    data-pr-position="bottom"
                    data-pr-classname="blue-tooltip"
                    className="disabled-button"
                  >
                    <Alphabet
                      letter={fn_firstcharacter(announcement.createdByName)}
                    />
                  </span>
                  {announcement.chats ? (
                    <div>
                      <i
                        className="pi pi-comments p-text-secondary p-overlay-badge mr-2 hover"
                        onClick={() => {
                          getComments(announcement._id);
                          setAnnouncementDetails(announcement);
                          setCommentDialog(!commentDialog);
                        }}
                        style={{ fontSize: "2rem" }}
                      >
                        <Badge
                          severity="warning"
                          value={announcement.commentsCount}
                        />
                      </i>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
